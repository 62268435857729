<template>
  <div>
    <div class="tabs">
      <ul v-sticky sticky-offset="offset">
        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'settings.general' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Γενικά Στοιχεία
            </a>
          </li>
        </router-link>

        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'settings.taxes.list' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">ΦΠΑ</a>
          </li>
        </router-link>

        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'settings.sendCharges' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Xρεώσεις Αποστολής
            </a>
          </li>
        </router-link>

        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'settings.checkoutMethods.list' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Τρόποι Πληρωμής
            </a>
          </li>
        </router-link>

        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'settings.sendMethods' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Τρόποι Αποστολής
            </a>
          </li>
        </router-link>

        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'settings.registrationMethods' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Τρόποι Αρχικής Εγγραφής
            </a>
          </li>
        </router-link>

        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'settings.emailTemplates.list' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Emails Επικοινωνίας
            </a>
          </li>
        </router-link>

        <router-link
          v-slot="{ href, navigate, isActive }"
          :to="{ name: 'settings.cancelOrderReasons.list' }"
          custom
        >
          <li :class="[isActive && 'router-link-active is-active']" role="link">
            <a :href="href" @keypress.enter="navigate" @click="navigate">
              Λόγοι Διαγραφής Παραγγελίας
            </a>
          </li>
        </router-link>
      </ul>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  data: () => ({
    offset: { top: 45 },
  }),
};
</script>
